import React from "react";
import ReactDOM from "react-dom/client";
import ChangedLoggedInUserRegistration from "../../../components/sign_up/changed_logged_in_user_registration";

function init() {
  const componentElements = document.querySelectorAll(
    '[data-react-class="sign_up/changed_logged_in_user_registration"]'
  );
  componentElements.forEach((rootElement) => {
    const classString = rootElement.getAttribute("data-react-class");
    const propsJson = rootElement.getAttribute("data-react-props");
    const props = rootElement && JSON.parse(propsJson ?? "");
    if (classString) {
      const root = ReactDOM.createRoot(
        document.getElementById("changed-logged-in-root-container") as HTMLElement
      );
      root.render(<ChangedLoggedInUserRegistration {...props} />);
    }
  });
}

if (document.readyState === "complete" || document.readyState === "interactive") {
  // document has at least been parsed
  init();
} else {
  document.addEventListener("DOMContentLoaded", () => {
    init();
  });
}
