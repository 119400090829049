import React from "react";
import styled, { ThemeProvider } from "styled-components";
import deprecatedTheme from "../styling/deprecated_theme";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import baseuiTheme from "../styling/baseui_theme";
import { Client as Styletron } from "styletron-engine-atomic";
import { LoginPage as LoginBackground } from "../login";
import {
  StyledCard,
  StyledHeading,
  StyledParagraphMedium,
} from "../shared/login_and_sign_up/styled";
import ProgramSchoolLogoBlock from "../shared/login_and_sign_up/program_school_logo_block";
import { Button } from "../components/base_ui/inputs/button";
import { KIND } from "baseui/button";

const engine = new Styletron();

const StyledCardParagraph = styled(StyledParagraphMedium)`
  margin-top: ${({ theme }) => theme.sizing.scale300};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.scale800};
  margin-bottom: 0px;

  button {
    margin-top: ${({ theme }) => theme.sizing.scale600};
    margin-bottom: 0px;
  }
`;

function ChangedLoggedInUserRegistration({
  programData,
  previouslyLoggedInUserEmail,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  programData: any;
  previouslyLoggedInUserEmail: string;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleContinue = async (event: any): Promise<void> => {
    event.preventDefault();
    // Remove all query params from the URL before continuing
    const url = new URL(location.pathname, location.href).href;
    window.location.assign(url);
  };

  const ssoBrandTitle = programData.ssoBrand || "Institutional Credentials";
  const ssoBrandLabel = programData.ssoBrand || "institutional credentials";

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={baseuiTheme}>
        <ThemeProvider theme={{ ...deprecatedTheme, ...baseuiTheme }}>
          <LoginBackground>
            <StyledCard>
              <div>
                <div className="brand-name text-align-center">
                  <ProgramSchoolLogoBlock
                    programLogo={programData.logo}
                    programName={programData.title}
                    schoolLogo={programData.schoolLogo}
                    schoolName={programData.schoolName}
                  />
                </div>
                <StyledHeading>You've Been Registered With Your {ssoBrandTitle}</StyledHeading>
                <StyledCardParagraph>
                  We noticed you were logged into your Mentor Collective account with{" "}
                  {previouslyLoggedInUserEmail}. You are now logged in with your {ssoBrandLabel}{" "}
                  instead. To access{" "}
                  {programData.programCustomLabel.singularMentorship ?? "mentorship"} information
                  for {programData.shortName} {programData.title}, please use your {ssoBrandLabel}{" "}
                  to log in.
                </StyledCardParagraph>
                <ButtonContainer>
                  <Button
                    className="continueOnboarding"
                    ariaLabel="continue"
                    isStretched={true}
                    kind={KIND.primary}
                    data-analytics-id="changed-user-registration-continue-onboarding"
                    onClick={handleContinue}
                  >
                    Continue Onboarding
                  </Button>
                </ButtonContainer>
              </div>
            </StyledCard>
          </LoginBackground>
        </ThemeProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default ChangedLoggedInUserRegistration;
